import {
	BootstrapIcon, 
	CssIcon, 
	FigmaIcon, 
	GitIcon,
	HtmlIcon,
	JavascriptIcon,
	LaravelIcon,
	MysqlIcon,
	PhpIcon,
	PostmanIcon,
	ReactjsIcon,
	SassIcon,
	TrelloIcon,
	WebpackIcon,
	TailwindIcon,
	GitlabIcon,
	DockerIcon,
	TypeScriptIcon
} from './TechStackIcons';

export const technologies = [
	{
		title: 'HTML',
		icon: HtmlIcon
	},

	{
		title: 'CSS',
		icon: CssIcon
	},

	{
		title: 'SASS',
		icon: SassIcon
	},

	{
		title: 'JavaScript',
		icon: JavascriptIcon
	},

	{
		title: 'ReactJS',
		icon: ReactjsIcon
	},

	{
		title: 'Bootstrap',
		icon: BootstrapIcon
	},

	{
		title: 'Git',
		icon: GitIcon		
	},

	{
		title: 'PHP',
		icon: PhpIcon,
	},

	{
		title: 'Laravel',
		icon: LaravelIcon
	},

	{
		title: 'MySQL',
		icon: MysqlIcon
	},

	{
		title: 'Postman',
		icon: PostmanIcon
	},

	{
		title: 'Trello',
		icon: TrelloIcon
	},

	{
		title: 'Figma',
		icon: FigmaIcon
	},

	{
		title: 'Webpack',
		icon: WebpackIcon
	},

	
	{
		title: 'Tailwind',
		icon: TailwindIcon
	},	
	{
		title: 'GitLab',
		icon: GitlabIcon
	},
	{
		title: 'Docker',
		icon: DockerIcon
	},
	{
		title: 'TypeScript',
		icon: TypeScriptIcon
	}
]
